.mySwiper .swiper-button-prev,
.mySwiper .swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 40px;
  margin-top: 0;
  background-color: #e0e9ef; 
  border-radius: 10px; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  transition: background-color 0.3s, color 0.3s;
}

.mySwiper .swiper-button-prev::after,
.mySwiper .swiper-button-next::after {
  font-size: 20px;
  color: #425a6d;
}

.mySwiper .swiper-button-prev:hover,
.mySwiper .swiper-button-next:hover {
  background-color: #1a85e2; 
  color: white; 
}

.mySwiper .swiper-button-prev:hover::after,
.mySwiper .swiper-button-next:hover::after {
  color: white; 
}

.mySwiper {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}
