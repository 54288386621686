/* swiper-custom.css */
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  bottom: -5vh !important; /* Adjust as needed */
  position: absolute;
  text-align: center;
  width: 100%;
}

.swiper-pagination-bullet {
  background: #007aff; /* Change color as needed */
}

.swiper-pagination-bullet-active {
  background: #0051b4; /* Change color as needed */
}
